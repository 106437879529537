import Vue from "vue";
import VueRouter from "vue-router";
import Vuex from "vuex";
Vue.use(Vuex);
import SiteLayout from "@/views/layout/SiteLayout";

// Site Views ==> Lazy lodading components
const HomeView = () => import("@/views/site/HomeView");
const Browse = () => import("@/views/site/browse/Browse");
const WishlistView = () => import("@/views/site/user/WishlistView");
const WishView = () => import("@/views/site/user/WishView");
const GiftView = () => import("@/views/site/user/GiftView");
const PageView = () => import("@/views/site/PageView");
const PrivacyView = () => import("@/views/site/PrivacyView");
const SingleBlog = () => import("@/views/site/SingleBlog");
const ContactForm = () => import("@/views/site/ContactForm");
const LoginView = () => import("@/views/site/LoginView");
const SignupView = () => import("@/views/site/SignupView");
const ForgotpasswordView = () => import("@/views/site/ForgotpasswordView");
const ProfileView = () => import("@/views/site/user/ProfileView");
const ResetPasswordView = () =>
  import("@/views/site/user/common/ResetPasswordView");
const ProductView = () => import("@/views/site/products/ProductView");
const PetproductView = () => import("@/views/site/products/PetproductView");
const PetlistView = () => import("@/views/site/user/PetlistView");
const PetwishlistView = () => import("@/views/site/user/PetwishlistView");
const BrowseView = () => import("@/views/site/browse/Browse");
const CompareView = () => import("@/views/site/user/CompareView");
const SingleProductView = () => import("@/views/site/user/SingleProductView");
const FavouriteView = () => import("@/views/site/user/FavouriteView");
const ContactsView = () => import("@/views/site/contact/ContactsView");
const ResetPassword = () => import("@/views/site/user/ResetPassword");
const FriendslistView = () => import("@/views/site/user/FriendslistView");
const GifteelistView = () => import("@/views/site/user/GifteelistView");
const FriendswishlistView = () =>
  import("@/views/site/user/FriendswishlistView");
const SearchView = () => import("@/views/site/search/SearchView");
const Occasionwishlist = () => import("@/views/site/user/Occasionwishlist");
const Occasiongift = () => import("@/views/site/user/Occasiongift");
const MyoccasionsView = () => import("@/views/site/user/MyoccasionsView");
const SearchSingle = () => import("@/views/site/search/SearchProduct");
const Petsview = () => import("@/views/site/pets/Petsview");
const DownloadApp = () => import("@/views/site/DownloadApp");
const FAQView = () => import("@/views/site/FAQView");
const NewSearch = () => import("@/views/site/search/NewSearch");
const TrendingGiftsView = () => import("@/views/site/TrendingGifts");
Vue.use(VueRouter);
var routes;
let userData = JSON.parse(localStorage.getItem("userData"));
var siteRoutesArray = [
  //Routes for sites
  {
    path: "/",
    component: HomeView,
    name: "Landing Page",
    meta: { title: "Home" },
  },
  {
    path: "/home",
    component: HomeView,
    redirect: () => {
      return { path: "/" };
    },
    name: "Home",
    meta: { title: "Home" },
  },
  {
    path: "/site/*",
    component: PageView,
    name: "Govava",
    meta: { title: "Govava" },
  },
  {
    path: "/PrivacyPolicy",
    component: PrivacyView,
    name: "PrivacyView",
    meta: { title: "PrivacyView" },
  },
  {
    path: "/blog/*",
    component: SingleBlog,
    name: "Blog",
    meta: { title: "Blog" },
  },
  {
    path: "browse",
    component: Browse,
    name: "Browse",
    meta: { title: "Browse" },
  },
  {
    path: "/login",
    component: LoginView,
    name: "Login",
    meta: { title: "Login" },
  },
  {
    path: "signup",
    component: SignupView,
    name: "Signup",
    meta: { title: "Sign up" },
  },
  {
    path: "Contact_us",
    component: ContactForm,
    name: "Contact form",
    meta: { title: "Contact form" },
  },
  {
    path: "forgot",
    component: ForgotpasswordView,
    name: "Forgot Password",
    meta: { title: "Forgot Password" },
  },
  {
    path: "/profile",
    component: ProfileView,
    name: "Profile",
    meta: { title: "Profile" },
  },
  {
    path: "wishlist_view",
    component: WishlistView,
    name: "Wishlist View",
    meta: { title: "Wishlist" },
  },
  {
    path: "wishlist",
    component: WishView,
    name: "Wishlist View",
    meta: { title: "Wishlist" },
  },
  {
    path: "gift_view",
    component: GiftView,
    name: "GiftView",
    meta: { title: "Gifts" },
  },
  {
    path: "products/:id",
    component: ProductView,
    name: "ProductView",
    meta: { title: "ProductView" },
    props: true,
  },
  {
    path: "browse",
    component: BrowseView,
    name: "BrowseView",
    meta: { title: "BrowseView" },
    props: true,
  },
  {
    path: "reset_password",
    component: ResetPasswordView,
    name: "Reset Password",
    meta: { title: "Reset Password" },
  },
  {
    path: "compare_view",
    component: CompareView,
    name: "Compare View",
    meta: { title: "Compare" },
  },
  {
    path: "single-product/product/*",
    component: SingleProductView,
    name: "SingleProductView",
    meta: { title: "Product " },
    props: true,
  },
  {
    path: "favourite_view",
    component: FavouriteView,
    name: "FavouriteView",
    meta: { title: "Favourite" },
  },
  {
    path: "trending-gifts",
    component: TrendingGiftsView,
    name: "TrendingGiftsView",
    meta: { title: "Trending Gifts" },
    props: true,
  },
  {
    path: "petproducts/:id",
    component: PetproductView,
    name: "PetproductView",
    meta: { title: "Product" },
    props: true,
  },
  {
    path: "contact",
    component: ContactsView,
    name: "Contact",
    meta: { title: "Contacts" },
  },
  {
    path: "password_reset/:token(.*)*",
    component: ResetPassword,
    name: "Contact",
    meta: { title: "Reset Password" },
  },
  {
    path: "friends_list",
    component: FriendslistView,
    name: "Friendslist",
    meta: { title: "Friends List" },
  },
  {
    path: "giftee_list",
    component: GifteelistView,
    name: "Friendslist",
    meta: { title: "Friends List" },
  },
  {
    path: "friendwishlist_view",
    component: FriendswishlistView,
    name: "FriendswishlistView",
    meta: { title: "Friends & Family WishList" },
  },
  {
    path: "Search_view/:id",
    component: SearchView,
    name: "SearchView",
    meta: { title: "Search View" },
  },
  {
    path: "pet_list",
    component: PetlistView,
    name: "PetlistView",
    meta: { title: "Pet List" },
  },
  {
    path: "pet_wishlist",
    component: PetwishlistView,
    name: "PetwishlistView",
    meta: { title: "Pet Wishlist" },
  },
  {
    path: "occasion_wishlist",
    component: Occasionwishlist,
    name: "Occasionwishlist",
    meta: { title: "Occasion wishlist" },
  },
  {
    path: "occasion_gift",
    component: Occasiongift,
    name: "Occasiongift",
    meta: { title: "Occasion Gift" },
  },
  {
    path: "my_occasion",
    component: MyoccasionsView,
    name: "MyoccasionsView",
    meta: { title: "Occasion View" },
  },
  {
    path: "search_single",
    component: SearchSingle,
    name: "SearchSingle",
    meta: { title: "Product " },
  },
  {
    path: "pets_view",
    component: Petsview,
    name: "Petsview",
    meta: { title: "Pets View " },
  },
  {
    path: "download",
    component: DownloadApp,
    name: "DownloadApp",
    meta: { title: "Download App " },
  },
  { path: "faq", component: FAQView, name: "FAQ", meta: { title: "FAQ" } },
  {
    path: "/search_staging/:id",
    component: NewSearch,
    name: "NewSearch",
    meta: { title: "NewSearch" },
  },
];
if (userData) {
  //Routes for User :: After login
  siteRoutesArray.push({
    path: "home",
    component: HomeView,
    name: "Home",
    meta: { title: "Home" },
  });
}
routes = [
  {
    path: "/",
    component: SiteLayout,
    children: siteRoutesArray,
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.PUBLIC_PATH,
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (to.hash) {
      return { selector: to.hash };
    } else {
      return { x: 0, y: 0 };
    }
  },
});

export default router;
