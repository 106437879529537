<template>
  <div id="footer-bar">
    <div id="demo" v-if="overlayVideo">
      <balloon
        position="bottom-left"
        @maximize="playVideo"
        @minimize="pauseVideo"
        v-on:close="closeVideo"
      >
        <div class="row d-flex align-items-center mx-0">
          <div class="col-md-7">
            <!-- <video
              autoplay
              loop
              muted
              class="baloon-video"
              :src="overlayVideo"
              type="video/mp4"
            >
              <br />
              <div class="mt-4">
                {{ description }}
                ,<br />
              </div>
            </video> -->
            <iframe
              class="baloon-video"
              :src="
                `https://www.youtube.com/embed/` +
                  overlayVideo +
                  `?autoplay=1&amp;mute=1&amp;modestbranding=1`
              "
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              allowfullscreen
            >
            </iframe>
          </div>
          <div class="col-md-5 mt-4" v-if="description">
            <p class="video-description">{{ description }}</p>
            <br />
          </div>
        </div>
      </balloon>
    </div>
    <!-- <footer>
    <div class="footer-middle">
      <div class="container">
        <div class="row">
          <div class="col-md-3 col-sm-4">
            <h4> QUICK LINKS </h4>
            <ul class="links">
              <li class="nav-item" >
              <router-link to="Contact_us">Contact Us</router-link>
              </li>
             <li class="nav-item" v-for="page in pages" :key="page.title">
              <router-link :to="`/site/${page.slug}`">{{ page.title }}</router-link>
              </li>
            </ul>
          </div>
          <div class="col-md-7 col-sm-4">
            <h4> News Letter </h4>
            <p>Sign up to get exclusive offers from our favorite brands.</p>
            <div class="newsletter">
              <form action="" method="post" accept-charset="utf-8">
                <input type="email" placeholder="Enter your email address"
                  class="input-text required-entry validate-email col-md-12" title="Sign up for our newsletter"
                  id="newsletter1" name="email" required><br>
         <center><button class="subscribe" title="Subscribe" type="submit" name="submit"><i class="fa fa-envelope"></i><span class="pl-2">Subscribe</span></button></center>       
              </form>
            </div>
          </div>
          <div class="col-md-2 col-sm-4">
            <h4> WE'RE SOCIAL </h4>
            <div class="social" id="footersocial">
              <ul>
                <li> <a href=""> <img
                      src=""> Instagram</a> </li>

                <li> <a href=""> <img
                      src=""> Twitter</a> </li>

                <li> <a href=""> <img
                      src=""> Facebook</a> </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="footer-bottom">
      <div class="container">
        <div class="row">
          <div class="col-sm-7 col-xs-12 coppyright"> &copy; 2020. All Rights Reserved. Designed by <a
              href="#">Govava.com</a> </div>
        </div>
      </div>
    </div>
    </footer> -->
    <footer class="page-footer">
      <div class="footer footer-wrapper">
        <div class="footer-container footer-style-1">
          <div class="footer-wrapper">
            <!-- <div class="back-to-top">
              
              <a id="yt-totop-fix" href="javascript:void(0)" title="Back to Top" style="display: none;"><span>Back
                  to Top</span></a>
            </div> -->
            <back-to-top bottom="50px" right="50px" visibleoffset="600">
              <button type="button" class="btn btn-info btn-to-top">
                <i class="fa fa-chevron-up"></i>
              </button>
            </back-to-top>

            <b-overlay :show="modal" rounded="sm">
              <div class="footer-middle">
                <div class="container">
                  <div class="row">
                    <div class="cat-footer">
                      <!-- <ul>
                        <li v-for="browsemenu in browsemenus">
                          <a href="#">
                            <span class="cat-icon"><img :src="browsemenu.image_name"
                                ></span>
                            <span class="cat-name">{{browsemenu.browse_name}}</span>
                          </a>
                        </li>
                      </ul> -->
                      <ul>
                        <li class="category-hover">
                          <a
                            @click="
                              ViewCategoryProduct(' Animals & Pet Supplies ')
                            "
                          >
                            <span class="cat-icon"
                              ><img
                                src="@/assets/site/images/icon/cat-12.png"
                                alt="Category Image"
                            /></span>
                            <span class="cat-name"
                              >Animals &amp; Pet Supplies</span
                            >
                          </a>
                        </li>

                        <li class="category-hover">
                          <a
                            @click="
                              ViewCategoryProduct('Apparel & Accessories')
                            "
                          >
                            <span class="cat-icon"
                              ><img
                                src="@/assets/site/images/icon/cat-4.png"
                                alt="Category Image"
                            /></span>
                            <span class="cat-name">Apparel & Accessories</span>
                          </a>
                        </li>

                        <li class="category-hover">
                          <a
                            @click="
                              ViewCategoryProduct(' Arts & Entertainment')
                            "
                          >
                            <span class="cat-icon"
                              ><img
                                src="@/assets/site/images/icon/cat-16.png"
                                alt="Category Image"
                            /></span>
                            <span class="cat-name">Arts & Entertainment</span>
                          </a>
                        </li>

                        <li class="category-hover">
                          <a @click="ViewCategoryProduct('Baby & Toddler')">
                            <span class="cat-icon"
                              ><img
                                src="@/assets/site/images/icon/cat-14.png"
                                alt="Category Image"
                            /></span>
                            <span class="cat-name">Baby & Toddler</span>
                          </a>
                        </li>

                        <li class="category-hover">
                          <a
                            @click="
                              ViewCategoryProduct('Business & Industrial')
                            "
                          >
                            <span class="cat-icon"
                              ><img
                                src="@/assets/site/images/icon/cat-17.png"
                                alt="Category Image"
                            /></span>
                            <span class="cat-name">Business & Industrial</span>
                          </a>
                        </li>

                        <li class="category-hover">
                          <a @click="ViewCategoryProduct('Cameras & Optics')">
                            <span class="cat-icon"
                              ><img
                                src="@/assets/site/images/icon/cat-18.png"
                                alt="Category Image"
                            /></span>
                            <span class="cat-name">Cameras & Optics</span>
                          </a>
                        </li>
                        <li class="category-hover">
                          <a @click="ViewCategoryProduct('Electronics')">
                            <span class="cat-icon"
                              ><img
                                src="@/assets/site/images/icon/cat-6.png"
                                alt="Category Image"
                            /></span>
                            <span class="cat-name">Electronics</span>
                          </a>
                        </li>

                        <li class="category-hover">
                          <a
                            @click="
                              ViewCategoryProduct('Food, Beverages & Tobacco')
                            "
                          >
                            <span class="cat-icon"
                              ><img
                                src="@/assets/site/images/icon/cat-19.png"
                                alt="Category Image"
                            /></span>
                            <span class="cat-name">Food, Bevs & Tobacco</span>
                          </a>
                        </li>

                        <li class="category-hover">
                          <a @click="ViewCategoryProduct('Furniture')">
                            <span class="cat-icon"
                              ><img
                                src="@/assets/site/images/icon/cat-20.png"
                                alt="Category Image"
                            /></span>
                            <span class="cat-name">Furniture</span>
                          </a>
                        </li>

                        <li class="category-hover">
                          <a @click="ViewCategoryProduct('Hardware')">
                            <span class="cat-icon"
                              ><img
                                src="@/assets/site/images/icon/cat-21.png"
                                alt="Category Image"
                            /></span>
                            <span class="cat-name">Hardware</span>
                          </a>
                        </li>

                        <li class="category-hover">
                          <a @click="ViewCategoryProduct(' Health & Beauty ')">
                            <span class="cat-icon"
                              ><img
                                src="@/assets/site/images/icon/cat-2.png"
                                alt="Category Image"
                            /></span>
                            <span class="cat-name">Health & Beauty</span>
                          </a>
                        </li>

                        <li class="category-hover">
                          <a @click="ViewCategoryProduct('Home & Garden')">
                            <span class="cat-icon"
                              ><img
                                src="@/assets/site/images/icon/cat-10.png"
                                alt="Category Image"
                            /></span>
                            <span class="cat-name">Home & Garden</span>
                          </a>
                        </li>

                        <li class="category-hover">
                          <a @click="ViewCategoryProduct(' Luggage & Bags ')">
                            <span class="cat-icon"
                              ><img
                                src="@/assets/site/images/icon/cat-24.png"
                                alt="Category Image"
                            /></span>
                            <span class="cat-name">Luggage & Bags</span>
                          </a>
                        </li>

                        <!-- <li class="category-hover">
                          <a @click="ViewCategoryProduct('Mature')">
                            <span class="cat-icon"
                              ><img
                                src="@/assets/site/images/icon/cat-22.png"
                                alt="Category Image"
                            /></span>
                            <span class="cat-name">Mature</span>
                          </a>
                        </li> -->

                        <li class="category-hover">
                          <a @click="ViewCategoryProduct('Media')">
                            <span class="cat-icon"
                              ><img
                                src="@/assets/site/images/icon/cat-23.png"
                                alt="Category Image"
                            /></span>
                            <span class="cat-name">Media</span>
                          </a>
                        </li>
                        <li class="category-hover">
                          <a @click="ViewCategoryProduct(' Office Supplies ')">
                            <span class="cat-icon"
                              ><img
                                src="@/assets/site/images/icon/cat-11.png"
                                alt="Category Image"
                            /></span>
                            <span class="cat-name">Office Supplies</span>
                          </a>
                        </li>
                        <li class="category-hover">
                          <a
                            @click="
                              ViewCategoryProduct('Religious & Ceremonial')
                            "
                          >
                            <span class="cat-icon"
                              ><img
                                src="@/assets/site/images/icon/cat-25.png"
                                alt="Category Image"
                            /></span>
                            <span class="cat-name">Religious & Ceremonial</span>
                          </a>
                        </li>
                        <li class="category-hover">
                          <a @click="ViewCategoryProduct('Software')">
                            <span class="cat-icon"
                              ><img
                                src="@/assets/site/images/icon/cat-28.png"
                                alt="Category Image"
                            /></span>
                            <span class="cat-name">Software</span>
                          </a>
                        </li>
                        <li class="category-hover">
                          <a @click="ViewCategoryProduct('Sporting Goods')">
                            <span class="cat-icon"
                              ><img
                                src="@/assets/site/images/icon/cat-29.png"
                                alt="Category Image"
                            /></span>
                            <span class="cat-name">Sporting Goods</span>
                          </a>
                        </li>
                        <li class="category-hover">
                          <a @click="ViewCategoryProduct('Toys & Games')">
                            <span class="cat-icon"
                              ><img
                                src="@/assets/site/images/icon/cat-27.png"
                                alt="Category Image"
                            /></span>
                            <span class="cat-name">Toys & Games</span>
                          </a>
                        </li>
                        <li class="category-hover">
                          <a @click="ViewCategoryProduct('Vehicles & Parts')">
                            <span class="cat-icon"
                              ><img
                                src="@/assets/site/images/icon/cat-26.png"
                                alt="Category Image"
                            /></span>
                            <span class="cat-name">Vehicles & Parts</span>
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>

                <div class="container">
                  <div class="middle-block">
                    <div class="row d-flex justify-content-between">
                      <div class="col-xl-2 col-lg-6 col-md-6">
                        <div class="block-footer">
                          <div class="block-footer-title">Quick Links</div>

                          <div class="block-footer-content">
                            <ul>
                              <li>
                                <router-link to="/Contact_us"
                                  >Contact Us</router-link
                                >
                              </li>
                              <li v-for="page in pages" :key="page.title">
                                <router-link :to="`/site/${page.slug}`">{{
                                  page.title
                                }}</router-link>
                              </li>
                              <!-- <li>
                                <router-link to="download">Get the Mobile App</router-link>
                              </li> -->
                            </ul>
                          </div>
                        </div>
                      </div>

                      <div class="col-xl-5 col-lg-12 col-md-12">
                        <div class="block-footer">
                          <div class="block-footer-title">
                            Newsletter Subscribe
                          </div>

                          <div class="block-footer-content">
                            <p class="newsletter-description">
                              Enter your email address for our mailing list to
                              keep yourself update
                            </p>
                            <div class="block-subscribe-footer">
                              <div class="title-middle-footer">
                                Sign Up For Newsletter
                              </div>
                              <!-- <form class="form subscribe" novalidate="novalidate" action="" method="post"
                                id="newsletter-footer-validate-detail"> -->

                              <div class="newsletter-content">
                                <div class="input-box">
                                  <input
                                    type="email"
                                    class="form-control"
                                    :class="{ 'is-invalid': errors.email }"
                                    id="newsletter-footer"
                                    v-model="email"
                                    placeholder="E Mail*"
                                  />
                                  <span
                                    class="text-danger"
                                    v-if="errors.email"
                                    >{{ this.errors.email[0] }}</span
                                  >
                                </div>

                                <div class="action-button">
                                  <button
                                    class="action subscribe primary"
                                    @click="subscribemail()"
                                    title="Subscribe"
                                    type="button"
                                  >
                                    <span>Subscribe</span>
                                  </button>
                                </div>
                              </div>
                              <!-- </form> -->
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="col-xl-4 col-lg-12 col-md-12">
                        <div class="block-footer">
                          <div class="block-footer-title">
                            <router-link to="faq">FAQ</router-link>
                          </div>
                          <!-- <div class="block-footer-title">
                            <router-link target="_blank" to="/download"
                              >Download Our Apps</router-link
                            >
                          </div> -->

                          <div class="block-footer-content content-apps">
                            <div class="row">
                              <div class="col-md-6 col-6">
                                <a
                                  href="https://apps.apple.com/in/app/govava/id1555186188"
                                  target="_blank"
                                  title="App Store"
                                  ><img
                                    src="@/assets/site/images/new/app-store.png"
                                    alt="App Store"
                                /></a>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <!-- <div class="seo-struct">
                    <ul>
                      <li><a href="#">Home</a></li>
                      <li><a href="#">Garden, Pool &amp; Patio</a></li>
                      <li><a href="#">Camping &amp; Outdoor</a></li>
                      <li><a href="#">Office &amp; Stationery</a></li>
                      <li><a href="#">Luggage &amp; Travel</a></li>
                      <li><a href="#">Health &amp; Beauty</a></li>
                      <li><a href="#">PetsSport</a></li>
                      <li><a href="#">Home &amp; Kitchen</a></li>
                      <li><a href="#">Baby &amp; Toddler</a></li>
                      <li><a href="#">Computers / Cameras</a></li>
                      <li><a href="#">TV, Audio &amp; Video</a></li>
                      <li><a href="#">Cellular &amp; GPS</a></li>
                      <li><a href="#">Movies &amp; TV</a></li>
                      <li><a href="#">Gaming</a></li>
                      <li><a href="#">Books</a></li>
                      <li><a href="#">Music</a></li>
                      <li><a href="#">Toys</a></li>
                      <li><a href="#">Fashion</a></li>
                      <li><a href="#">Vouchers</a></li>
                    </ul>
                  </div> -->
                </div>
              </div>
            </b-overlay>
            <div class="row">
              <cookie-law theme="blood-orange">
                <div slot-scope="props" style="width: 100%;">
                  <p>
                    We use cookies to ensure our website works effectively, to
                    improve your experience on our website and to show you
                    personalized content and advertising. To find out more about
                    the cookies this website uses, please see our policy.
                  </p>

                  <div class="row">
                    <div class="col-md-11">
                      <p>
                        The term 'Etsy' is a trademark of Etsy, Inc. This
                        application uses the Etsy API but is not endorsed or
                        certified by Etsy, Inc.
                      </p>
                    </div>
                    <div class="col-md-1">
                      <button class="skew pull-right" @click="props.accept">
                        <span>I accept</span>
                      </button>
                    </div>
                  </div>
                </div>
              </cookie-law>
            </div>

            <div class="footer-bottom">
              <div class="container">
                <div class="row">
                  <div class="col-lg-4">
                    <!-- <div class="footer-payment">
                      <img src="@/assets/site/images/footer/payment-footer.png" alt="Payment">
                    </div> -->
                  </div>

                  <div class="col-lg-8">
                    <div class="copyright-footer">
                      <address>Govava © 2023. All rights reserved.</address>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <b-modal
          id="loader-modal"
          :no-close-on-backdrop="true"
          class="modal-dialog modal-dialog-centered modal-lg"
          scrollable
          ref="my-modal"
          hide-footer
          hide-header
          size="lg"
        >
          <section>
            <div>
              <div class="row">
                <img
                  src="@/assets/site/images/loader.gif"
                  class="img-fluid loader-width"
                  alt
                />
              </div>
            </div>
          </section>
        </b-modal>
      </div>
    </footer>
  </div>
</template>
<script>
import BackToTop from "vue-backtotop";
import CookieLaw from "vue-cookie-law";
import { Balloon } from "vue-balloon";
export default {
  name: "FooterBar",
  components: { CookieLaw, Balloon, BackToTop },
  data() {
    return {
      loader: false,
      errors: [],
      pages: [],
      browsemenus: [],
      email: null,
      components: {
        BackToTop,
      },
      modal: false,
      search_word: null,
      cookie_message: "",
      overlayVideo: null,
      description: null,
      route_name: "",
    };
  },
  created() {
    this.getPages();
    this.getBrowsmenus();
    this.getOverlayVideo();
    this.checkurl();
    // this.getCoutryCode();
  },
  methods: {
    playVideo() {
      // this.$refs.player.player.playVideo();
    },
    pauseVideo() {
      // this.$refs.player.player.pauseVideo();
    },
    getImgUrl(image) {
      return image;
    },
    getCoutryCode() {
      // var country_code = localStorage.getItem("countryCode");
      // var countryCode = JSON.parse(country_code);
      if (localStorage.getItem("countryCode") === null) {
        var api = process.env.VUE_APP_LOCATION;

        fetch("https://ep.api.getfastah.com/whereis/v1/json/auto", {
          mode: "cors",
          headers: { "Fastah-Key": api },
        })
          .then((response) => response.json())
          .then((data) => {
            localStorage.setItem(
              "countryCode",
              JSON.stringify(data.locationData.countryCode)
            );
            // var country_code = localStorage.getItem("countryCode");
            // var countryCode = JSON.parse(country_code);
            // console.log('countrycode :'+countryCode);
          });
      }
    },
    getPages() {
      this.errors = [];
      var token = localStorage.getItem("userData");
      var userdata_array = JSON.parse(token);
      //  var api_token = userdata_array.token;
      let method_type = "";
      let fetch_url = "";
      method_type = "post";
      fetch_url = process.env.VUE_APP_URL + "customer/page/getPages";
      fetch(fetch_url, {
        method: method_type,
        headers: {
          "content-type": "application/json",
          "X-Requested-With": "XMLHttpRequest",
        },
      })
        .then((res) => res.json())
        .then((data) => {
          this.loader = false;
          this.errors = [];
          this.pages = data.page;
        });
    },
    getBrowsmenus() {
      this.loader = true;
      var token = localStorage.getItem("userData");
      if (token != null) {
        var userdata_array = JSON.parse(token);
        var api_token = userdata_array.token;
      }
      var fetch_url =
        process.env.VUE_APP_URL + "customer/browsemenu/getBrowseMenu";
      fetch(fetch_url, {
        method: "get",
        headers: {
          "content-type": "application/json",
          "X-Requested-With": "XMLHttpRequest",
          Authorization: "Bearer " + api_token,
        },
      })
        .then((res) => res.json())
        .then((data) => {
          this.loader = false;
          this.browsemenus = data.browsemenu;
        })
        .catch(function(err) {
          console.log("Fetch Error :-S", err);
        });
    },

    subscribemail() {
      //Save or update User Details
      this.errors = [];
      // this.loader = true;
      let apiUrl = process.env.VUE_APP_URL + "customer/saveSubscribemail";
      let method_type = "post";
      fetch(apiUrl, {
        method: method_type,
        headers: {
          "content-type": "application/json",
          "X-Requested-With": "XMLHttpRequest",
          // Authorization: "Bearer " + $('meta[name="api_token"]').attr("content")
        },
        body: JSON.stringify({
          email: this.email,
        }),
      })
        .then((res) => res.json())
        .then((data) => {
          if (data.status === true) {
            this.resetForm();
            this.$swal("Success", data.message, "success");
            this.resetForm();
            // this.showModal();
          } else if (data.status === false) {
            this.$swal("Error", data.message, "error");
          } else {
            this.errors = data.errors;
          }
          this.loader = false;
        })
        .catch(function(err) {
          console.log("Conatct Data", "Error : " + err.message, "error");
        });
    },
    resetForm() {
      this.email = null;
    },
    ViewCategoryProduct(category) {
      // this.modal = true;
      this.$bvModal.show("loader-modal");
      var fetch_url =
        process.env.VUE_APP_URL + "customer/search/keyword_search";
      fetch(fetch_url, {
        method: "post",
        headers: {
          "content-type": "application/json",
          "X-Requested-With": "XMLHttpRequest",
          // Authorization: "Bearer " + api_token,
        },
        body: JSON.stringify({
          keyword: this.search_word,
          category: category,
          page: 1,
        }),
      })
        .then((res) => res.json())
        .then((data) => {
          //  this.modal = false;
          this.$bvModal.hide("loader-modal");
          window.scrollTo(0, 0);
          this.modal = false;
          var products = data.response;
          // const id = "view_" + i;

          const id = Math.random()
            .toString(36)
            .slice(2);
          this.$store.state.keyword = this.search_word;
          this.$store.state.category = category;
          this.$store.state.products = data.response.item;
          this.$store.state.totalPages = data.response.TotalPages;
          this.$store.state.page = 1;
          this.$router.replace({
            name: "SearchView",
            params: {
              id,
            },
          });
          //
          // this.search_word = null;
          // this.selected_category = '';
        })
        .catch(function(err) {
          console.log("Fetch Error :-S", err);
        });
    },
    getOverlayVideo() {
      var videoStatus = localStorage.getItem("videoStatus");
      console.log(videoStatus);
      if (videoStatus == null) {
        var fetch_url = process.env.VUE_APP_URL + "customer/videos/getVideo";
        // var fetch_url = "https://core-api.govava.com/customer/videos/getVideo";
        fetch(fetch_url, {
          method: "post",
          headers: {
            "content-type": "application/json",
            "X-Requested-With": "XMLHttpRequest",
            // Authorization: "Bearer " + api_token,
          },
          body: JSON.stringify({
            keyword: this.search_word,
          }),
        })
          .then((res) => res.json())
          .then((data) => {
            this.overlayVideo = data.video.video;
            this.description = data.video.description;
            // localStorage.setItem("videoStatus", JSON.stringify(true));
          })
          .catch(function(err) {
            console.log("Fetch Error :-S", err);
          });
      }
    },
    closeVideo() {
      localStorage.setItem("videoStatus", JSON.stringify(false));
    },
    checkurl() {
      var segment_str = window.location.pathname;
      var segment_array = segment_str.split("/");
      this.route_name = segment_array[1];
    },
  },
  watch: {
    $route(to, from) {
      let split_route = to.path.split("/");
      this.route_name = split_route[1];
    },
  },
};
</script>
<style>
/* .btn-to-top {
        width: 60px;
        height: 60px;
        padding: 10px 16px;
        border-radius: 50%;
        font-size: 22px;
        line-height: 22px;
    } */
.category-hover {
  cursor: pointer;
}
.vb.vb-bottom-left.vb.vb-maximized {
  width: 600px !important;
  height: 500px;
}

.vb.vb-bottom-left.vb.vb-maximized .col-md-5 {
  max-width: 100% !important;
  flex: none !important;
}

.vb-wrapper .vb-header,
.vb-wrapper:not(.vb-multiple) .vb-header {
  background: #fff !important;
  color: #777;
  padding: 0px !important;
  height: 2px !important;
  border-bottom: none !important;
  -webkit-box-sizing: content-box;
  box-sizing: content-box;
}
.vb-wrapper .vb-content,
.vb-wrapper:not(.vb-multiple) .vb-content {
  position: relative;
  width: auto !important;
  height: auto !important;
  min-width: 350px !important;
  max-width: 550px !important;
  max-height: 177px;
  overflow: hidden !important;
  background: #fff !important;
}
.vb-wrapper .vb-content-slot,
.vb-wrapper:not(.vb-multiple) .vb-content-slot {
  -webkit-transform-origin: 0% 0%;
  transform-origin: 0% 0%;
  -webkit-transform: scale(1);
  transform: scale(1);
  width: 550px !important;
  height: 100%;
}
.vb-wrapper .vb-header .vb-buttons,
.vb-wrapper:not(.vb-multiple) .vb-header .vb-buttons {
  float: right;
  padding-right: 5px !important;
}
.vb-wrapper .vb-header .vb-buttons,
.vb-wrapper:not(.vb-multiple) .vb-header .vb-buttons {
  float: right;
  padding-right: 5px !important;
  position: absolute !important;
  right: 2% !important;
  top: 1% !important;
  bottom: 80% !important;
  z-index: 9999 !important;
}
.baloon-video {
  height: 100% !important;
  /* border-radius: 15px !important; */
  /* width: 100% !important; */
}
.vb.vb-bottom-left.vb.vb-maximized iframe {
  width: 530px !important;
  height: 350px !important;
}
.vb-wrapper .vb-minimized-overlay,
.vb-wrapper:not(.vb-multiple) .vb-minimized-overlay .vb-content {
  max-height: 177px !important;
}
.vb {
  border-radius: 15px !important;
  padding-left: 7px !important;
  padding-top: 5px !important;
  padding-bottom: 5px !important;
  overflow: hidden !important;
  background: #fff !important;
}
.vb-wrapper .vb-minimized-overlay,
.vb-wrapper:not(.vb-multiple) .vb-minimized-overlay {
  -webkit-transition: opacity 0.25s;
  transition: opacity 0.25s;
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  opacity: 0;
  background: none !important;
  cursor: pointer;
}

@media (max-width: 767px) {
  .vb-wrapper .vb-content,
  .vb-wrapper:not(.vb-multiple) .vb-content {
    position: relative;
    width: 280px !important;
    height: auto !important;
    max-width: 275px !important;
    /* max-height: 160px !important; */
    overflow: hidden !important;
    background: #fff !important;
  }
  .vb.vb-bottom-left {
    /* width: 290px !important; */
  }
  .vb-wrapper .vb-content-slot,
  .vb-wrapper:not(.vb-multiple) .vb-content-slot {
    -webkit-transform-origin: 0% 0%;
    transform-origin: 0% 0%;
    -webkit-transform: scale(1);
    transform: scale(1);
    width: 280px !important;
    height: 100%;
  }
}
.video-description {
  line-height: 1.5;
}
</style>
